import './_vendor';
import './_functions';
import './_components';
import Swiper from './components/swiper-bundle.min';

(function() {
    'use strict';

    // breakpoint where swiper will be destroyed
    // and switches to a dual-column layout
    const breakpoint = window.matchMedia( '(min-width: 992px)' );

    // keep track of swiper instances to destroy later
    let mySwiper;

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const breakpointChecker = function() {

      // if larger viewport and multi-row layout needed
      if ( breakpoint.matches === true ) {

        // clean up old instances and inline styles when available
        if ( mySwiper !== undefined ) mySwiper.destroy( true, true );

        // or/and do nothing
        return;

        // else if a small viewport and single column layout needed
        } else if ( breakpoint.matches === false ) {

          // fire small viewport version of swiper
          return enableSwiper();

        }

    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    const enableSwiper = function() {
      mySwiper = new Swiper('.gallery-swiper', {
        slidesPerView: 1,
        simulateTouch: false,
        watchOverflow: true,
        watchSlidesVisibility: true,
        cssMode: false,
        loop: false,
        allowTouchMove: true,
        navigation: {
        nextEl: '',
        prevEl: '',
      },
        pagination: {
        el: '.dots',
        clickable: true,
      },
        mousewheel: {
        forceToAxis: true,
      },
        touchReleaseOnEdges: true,
        keyboard: false,
      });

    };

    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////

    // keep an eye on viewport size changes
    breakpoint.addListener(breakpointChecker);

    // kickstart
    breakpointChecker();



  })(); /* IIFE end */

/*=============== HOME SWIPER ===============*/
let homeSlider = new Swiper('.home-swiper', {    
    simulateTouch: false,
    watchOverflow: true,
    slidesPerView: 1,
    effect: 'fade',
    mousewheel: false,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    centeredSlides: true,
    cssMode: false,
    loop: true,
    speed: 500,
    allowTouchMove: true,
    pagination: {
        el: '.dots, .disc-num',
        clickable: true,
    },
    mousewheel: {
        forceToAxis: true,
    },
    keyboard: false,
    navigation: {
        nextEl: '#next--intro',
        prevEl: '#prev--intro',
    },
    keyboard: false
});

let stagesSwiper = new Swiper('.stages-swiper', {
  slidesPerView: 5,
  simulateTouch: false,
  watchOverflow: true,
  watchSlidesVisibility: true,
  cssMode: false,
  loop: false,
  navigation: {
  nextEl: '',
  prevEl: '',
  allowTouchMove: true,
},
  pagination: {
  el: '.dots',
  clickable: true,
},
  mousewheel: {
  forceToAxis: true,
},
  touchReleaseOnEdges: true,
  keyboard: false,
  breakpoints: {
      0: {
          slidesPerView: 'auto',
      },
      575: {
          slidesPerView: 'auto',
      },
      767: {
          slidesPerView: 3,
      },
      991: {
          slidesPerView: 3,
      },
      1200: {
          slidesPerView: 4,
      },
      1440: {
          slidesPerView: 5,
      },
  }
});

let worksSwiper = new Swiper('.works-swiper', {
  simulateTouch: false,
  watchOverflow: true,
  watchSlidesVisibility: true,
  cssMode: false,
  loop: false,
  navigation: {
  nextEl: '#nextSeeAlso',
  prevEl: '#prevSeeAlso',
  allowTouchMove: true,
},
  pagination: {
  el: '.dots',
  clickable: true,
},
  mousewheel: {
  forceToAxis: true,
},
  touchReleaseOnEdges: true,
  keyboard: false,
  breakpoints: {
      0: {
          slidesPerView: 1,
      },
      575: {
          slidesPerView: 2,
      },
      767: {
          slidesPerView: 3,
      },
  }
});

// Button target

const triggers = document.querySelectorAll('[data-target]');

triggers.forEach(trigger => {
  trigger.addEventListener('click', () => {
    const target = document.querySelector(trigger.getAttribute('data-target'));

      if (trigger.classList.contains('active')) {
        trigger.classList.remove('active');
        document.body.classList.remove('active')
      } else {
        trigger.classList.add('active');
        document.body.classList.add('active')
      }



    if (target) {
      if (target.classList.contains('active')) {
        target.classList.remove('active');
        target.nextSibling().classList.remove('active');
        document.body.classList.remove('active');
      } else {
        target.classList.add('active');
        target.nextElementSibling.classList.add('active');
        document.body.classList.add('active');
      }
    }
  });
});

// Fancybox
Fancybox.bind("[data-fancybox]", {
  
});

// Scroll to anchors
(function () {

  document.querySelectorAll('a[href^="#"]').forEach(elem => {
    elem.addEventListener('click', e => {
        e.preventDefault();
        let block = document.querySelector(elem.getAttribute('href')),
            offset = elem.dataset.offset ? parseInt(elem.dataset.offset) : 0,
            bodyOffset = document.body.getBoundingClientRect().top;
        window.scrollTo({
            top: block.getBoundingClientRect().top - bodyOffset + offset,
            behavior: "smooth"
        }); 
    });
  });
}());