// Реализация бургер-меню
// import { burger } from './functions/burger';

// Реализация остановки скролла
// import { disableScroll } from './functions/disableScroll';

// Реализация включения скролла
// import { enableScroll } from './functions/disableScroll';

// Реализация модального окна
// import GraphModal from 'graph-modal';
// const modal = new GraphModal();
